import { useCallback } from "react";
import { compareIds } from "../globalFunc";
import useCurrentPosition from "./useCurrentPosition";

export default function useKsa(regionsMap = new Map()) {
    const { country } = useCurrentPosition() ?? {};

    return useCallback((fallback = null) => {
        try {
            regionsMap.forEach((countries, region) => {
                if (countries.some(ksaCountry => compareIds(ksaCountry, country))) {
                    throw region;
                }
            });
        } catch (region) {
            return region;
        }

        return fallback;
    }, [country]);
};
