import { KsaRegion } from "@/components/Filler";
import filler from "@/content/filler.json";
import { ksaRegions } from "@/static/ksa";
import useKsa from "@/utils/customHooks/useKsa";

export default function useContent({ slides = filler, isMobile } = {}) {
    const getKsaRegion = useKsa(ksaRegions);

    return slides.map(({ alt, title, description, ...rest }) => ({
        image: rest[getKsaRegion(KsaRegion.Default)].images[Number(!isMobile)],
        alt,
        title,
        description
    }));
};
